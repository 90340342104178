@font-face {
  font-family: "pix Chicago";
  src: url("pixChicago.woff2") format("woff2"),
    url("pixChicago.woff") format("woff"),
    url("pixChicago.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --screen-color: #caccbf;
  --font-color: #3d403e;
  --label-font-size: 9pt;
  --theme-color: #f0eeee;
  --wheel-color: #cdcdce;
  --wheel-outline-color: transparent;
  /* --center-wheel-outline-color: transparent; */
  --center-wheel-outline-color: #d3d3d5;
  --screen-shadow-color: rgba(0, 0, 0, 0.15);
  --screen-border-color: #d9d9d9;
}
